import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, FormLabel,Toolbar} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup} from '@material-ui/core';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';


import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

class BlockDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners:[],
      block: {
        name: '',
        url: '',
        titleVN: '',
        titleEN: '',
        detailVN: '',
        detailEN: ''
      },
      error: {
        vn: false,
        en: false,
        name_error: false,
        url_error: false,
        detailVN_error: false,
        detailEN_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      options: {'empty':'empty'}
    }
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('blockcontent',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            data._id = item._id;
            $this.setState({block: data});
          }
        });
      });
    }

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });


  }
  onEditorStateChange(field,value){
    var block = this.state.block,
        error = this.state.error;
    block[field] = value;
    error[field+'_error'] = value=='';
    this.setState({block:block,error:error});

  }
  handleFieldChange = (flag) => (event) => {
    var $this = this,
        block = this.state.block;
    block[event.target.id] = event.target.value;
    this.setState({block:block});
  }
  handleValidate(e,flag) {
    var $this = this,
        block = this.state.block,
        obj = this.state.error;

    block[e.target.id] = e.target.value;

    if(e.target.id==='name'){
      var url = Utils.getFriendlyURL(e.target.value);
      block.url = url;
    }
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;


    this.setState({block: block, error:obj}, () => {
      if(!$this.state.error.detailVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.detailEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      $this.setState({error:obj});
    });
  }
  _save(){
    var $this = this;
    var data = this.state.block;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('blockcontent',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/shareblocks/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('blockcontent',data, function(res) {

        if (res.status === 'success') {
          $this.context.router.history.push('/shareblocks/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['detailVN_error'] = this.state.block.detailVN === '';
          obj['vn'] = obj['detailVN_error'];
          this.setState({error:obj},function(){
            //console.log($this.state.error[flag]);
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['detailEN_error'] = this.state.block.detailEN === '';
          obj['en'] = obj['detailEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/shareblocks/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleCopy(){
    var data = this.state.block;
    data.detailEN = data.detailVN;
    this.setState({
      block: data
    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;

    const editorConfig = Utils.setupEditor('others',this.state.options);


    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa nội dung</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="name" label="Tên block" className={classes.textField} value={this.state.block.name} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.name_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="url" label="URL" className={classes.textField} value={this.state.block.url} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.url_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            {/* <TextField id="titleVN" label="Tiều đề" className={classes.textField} value={this.state.block.titleVN} onChange={this.handleFieldChange('vn')} margin="normal" InputLabelProps={{
              shrink: true
            }}/> */}
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailVN_error}>{language.VN.detail}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={this.state.block.detailVN}
                  onModelChange={(value)=>this.onEditorStateChange('detailVN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            {/* <TextField id="titleEN" label="Title" className={classes.textField} value={this.state.block.titleEN} onChange={this.handleFieldChange('vn')} margin="normal" InputLabelProps={{
              shrink: true
            }}/> */}
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailEN_error}>{language.EN.detail}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={this.state.block.detailEN}
                  onModelChange={(value)=>this.onEditorStateChange('detailEN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
      </div>
    )
  }
}

BlockDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
BlockDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BlockDetail);
